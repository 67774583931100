import React from "react";

interface PropTypes {
  width?: number;
  height?: number;
  fill?: string;
}

export default function InspiredCapitalLogo(props: PropTypes) {
  const fill = props.fill || "#000000";

  return (
    <svg
      width={props.width}
      height={props.height}
      fill={fill}
      viewBox={`0 0 114 24`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Inspired Capital</title>
      <path d="M1.18118 7.46861V0.152866H0V7.46861H1.18118Z" />
      <path d="M18.7772 0.152866H17.585V5.49227L13.1252 0.152866H12.1207V7.46861H13.3018V2.21656L17.7727 7.46861H18.7772V0.152866Z" />
      <path d="M32.3009 0C30.6561 0 29.5853 0.873521 29.5853 2.16196C29.5853 3.46133 30.5346 3.94177 31.4509 4.14923L33.0626 4.4768C33.8022 4.62966 34.1002 4.89172 34.1002 5.44859C34.1002 6.11465 33.5372 6.58417 32.5437 6.58417C31.5502 6.58417 30.8106 6.12557 30.634 5.18653L29.3755 5.44859C29.5632 6.86806 30.8658 7.62147 32.4333 7.62147C34.1334 7.62147 35.2704 6.75887 35.2704 5.38308C35.2704 4.23658 34.4756 3.62511 33.3717 3.3849L31.771 3.04641C31.1307 2.91538 30.8327 2.6424 30.8327 2.12921C30.8327 1.48499 31.4067 1.01547 32.2788 1.01547C33.1619 1.01547 33.8353 1.52866 33.9678 2.30391L35.1489 2.06369C34.884 0.808008 33.8022 0 32.3009 0Z" />
      <path d="M45.9134 0.152866V7.46861H47.0945V4.89172H49.2472C50.8809 4.89172 51.9076 3.93085 51.9076 2.51137C51.9076 1.11374 50.8809 0.152866 49.2472 0.152866H45.9134ZM47.0945 3.82166V1.21201H49.2472C50.1744 1.21201 50.7264 1.75796 50.7264 2.51137C50.7264 3.29754 50.1744 3.82166 49.2472 3.82166H47.0945Z" />
      <path d="M63.5597 7.46861V0.152866H62.3785V7.46861H63.5597Z" />
      <path d="M80.8577 7.46861L78.1862 4.54231C79.5882 4.46588 80.5154 3.61419 80.5154 2.35851C80.5154 1.03731 79.4999 0.152866 77.9765 0.152866H74.4992V7.46861H75.6804V4.55323H76.7511L79.3895 7.46861H80.8577ZM75.6804 3.49409V1.21201H77.9765C78.7934 1.21201 79.3343 1.67061 79.3343 2.35851C79.3343 3.03549 78.7934 3.49409 77.9765 3.49409H75.6804Z" />
      <path d="M96.6237 6.40946H92.2964V4.32393H96.2815V3.27571H92.2964V1.21201H96.6127V0.152866H91.1152V7.46861H96.6237V6.40946Z" />
      <path d="M107.332 0.152866V7.46861H109.916C112.432 7.46861 114 5.93995 114 3.81074C114 1.68153 112.432 0.152866 109.916 0.152866H107.332ZM108.514 6.40946V1.21201H109.916C111.715 1.21201 112.808 2.29299 112.808 3.81074C112.808 5.32848 111.715 6.40946 109.916 6.40946H108.514Z" />
      <path d="M13.5832 22.8972C12.0929 22.8972 10.9228 21.7288 10.9228 20.1893C10.9228 18.6497 12.0929 17.4813 13.5832 17.4813C14.7423 17.4813 15.6916 18.0928 16.0228 19.3267L17.1709 18.9991C16.7514 17.3831 15.3274 16.3785 13.5832 16.3785C11.4306 16.3785 9.73056 18.0601 9.73056 20.1893C9.73056 22.3294 11.4306 24 13.5832 24C15.3274 24 16.7624 23.0064 17.1709 21.2921L16.0228 21.0519C15.6916 22.2857 14.7423 22.8972 13.5832 22.8972Z" />
      <path d="M33.2582 23.8471L30.0017 16.5314H28.8978L25.6412 23.8471H26.9218L27.6503 22.1438H31.2491L31.9777 23.8471H33.2582ZM30.7854 21.0737H28.114L29.4497 17.9618L30.7854 21.0737Z" />
      <path d="M42.2829 16.5314V23.8471H43.4641V21.2702H45.6167C47.2505 21.2702 48.2771 20.3094 48.2771 18.8899C48.2771 17.4923 47.2505 16.5314 45.6167 16.5314H42.2829ZM43.4641 20.2002V17.5905H45.6167C46.544 17.5905 47.0959 18.1365 47.0959 18.8899C47.0959 19.6761 46.544 20.2002 45.6167 20.2002H43.4641Z" />
      <path d="M58.8253 23.8471V16.5314H57.6441V23.8471H58.8253Z" />
      <path d="M74.4343 17.5905V16.5314H68.0427V17.5905H70.6369V23.8471H71.8291V17.5905H74.4343Z" />
      <path d="M89.7981 23.8471L86.5415 16.5314H85.4376L82.1811 23.8471H83.4617L84.1902 22.1438H87.789L88.5175 23.8471H89.7981ZM87.3253 21.0737H84.6539L85.9896 17.9618L87.3253 21.0737Z" />
      <path d="M104.044 22.788H100.004V16.5314H98.8228V23.8471H104.044V22.788Z" />
    </svg>
  );
}
