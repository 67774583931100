import React from "react";

const SharedMetaTags: React.FC = () => {
  return (
    <>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
      />

      <link rel="icon" href="/img/icon.png" sizes="192x192" />
      <link rel="apple-touch-icon" href="/img/icon.png" />
      <meta name="msapplication-TileImage" content="/img/icon.png" />

      <meta
        name="robots"
        content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
      />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Scythe - Robots for a Greener World" />
      <meta
        property="og:description"
        content="Self-driving, all-electric mowers for commercial landscaping.  Learn how Scythe can help your business do more."
      />
      <meta property="og:url" content="https://scytherobotics.com" />
      <meta property="og:site_name" content="Scythe™" />
      <meta
        property="og:image"
        content="https://scytherobotics.com/img/mow-smarter.jpg"
      />
      <meta
        property="og:image:alt"
        content="Self-driving, all-electric mowers for commercial landscaping.  Learn how Scythe can help your business do more."
      />
      <meta
        property="twitter:image"
        content="https://scytherobotics.com/img/mow-smarter-twitter.jpg"
      />
      <meta
        name="twitter:card"
        content="https://scytherobotics.com/img/mow-smarter-twitter.jpg"
      />
      <meta
        name="twitter:description"
        content="Self-driving, all-electric mowers for commercial landscaping.  Learn how Scythe can help your business do more."
      />
    </>
  );
};

export default SharedMetaTags;
