import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, createRef } from "react";
import Modal from "react-modal";

import ReactPlayer from "react-player";

interface PropTypes {
  open: (evt: any) => void;
  close: (evt: any) => void;
  isOpen: boolean;
}

export default function VideoModal(props: PropTypes) {
  let { open, close, isOpen } = props;
  let videoRef = createRef() as any;

  Modal.setAppElement("#__next");

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={close}
      style={{
        content: {
          width: "80%",
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          zIndex: 2001,
          background: "transparent",
          border: 0,
        },
        overlay: {
          zIndex: 2000,
          background: "rgba(0,0,0,0.9)",
          // backdropFilter: "blur(2px)", // This is nice, but there's a flicker bug in chrome :/
        },
      }}
      contentLabel="Scythe Promo Video"
    >
      <div className="video-modal">
        <a
          className="btn-close align-self-end mb-3"
          href="#"
          onClick={(e) => close(e)}
        >
          <FontAwesomeIcon icon={faTimes} size="2x" color="#00b140" />
        </a>
        <div className="player-wrapper">
          <ReactPlayer
            ref={videoRef}
            url="https://www.youtube.com/embed/wqwDldk2zH8?fs=1&autoplay=1&rel=0&enablejsapi=1"
            className="react-player"
            width="100%"
            height="100%"
            controls={true}
            playsInline={false}
            playing={true}
            allowFullScreen
          />
        </div>
      </div>
    </Modal>
  );
}
