import React from "react";

interface PropTypes {
  width?: number;
  height?: number;
  fill?: string;
}

export default function ZiggLogo(props: PropTypes) {
  const fill = props.fill || "#ffffff";

  return (
    <svg
      width={props.width}
      height={props.height}
      fill={fill}
      viewBox="0 0 209 104"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Zigg Capital</title>
      <path d="M6.28992 52.0899L25.0599 11.6599H2.66992V0.919922H45.5999L22.7999 52.0899H6.28992Z" />
      <path d="M39.31 52.0898L20.54 92.5198H42.93V103H0L22.8 52.0898H39.31Z" />
      <path d="M99.5301 25.0006C99.4553 21.2625 100.185 17.552 101.67 14.1206C102.994 11.1171 104.948 8.43319 107.4 6.25063C109.69 4.24887 112.336 2.69525 115.2 1.67063C119.485 0.130641 124.1 -0.256859 128.582 0.546911C133.064 1.35068 137.257 3.31753 140.74 6.25063C143.115 8.46794 145.014 11.1457 146.32 14.1206C147.813 17.5498 148.546 21.2614 148.47 25.0006V30.1506H133.87V25.0006C133.87 21.474 132.893 18.874 130.94 17.2006C128.991 15.5788 126.536 14.6908 124 14.6908C121.465 14.6908 119.009 15.5788 117.06 17.2006C115.1 18.8673 114.12 21.4673 114.12 25.0006V78.7806C114.12 82.314 115.1 84.914 117.06 86.5806C119.012 88.197 121.466 89.0814 124 89.0814C126.534 89.0814 128.989 88.197 130.94 86.5806C132.887 84.9073 133.863 82.3073 133.87 78.7806V57.6006H122.28L127.51 46.7206H148.51V78.7806C148.611 82.5608 147.877 86.3168 146.36 89.7806C145.05 92.6696 143.15 95.2518 140.78 97.3606C138.46 99.4276 135.764 101.03 132.84 102.081C127.163 104.187 120.918 104.187 115.24 102.081C112.349 101.049 109.696 99.4433 107.44 97.3606C104.995 95.2867 103.039 92.6984 101.71 89.7806C100.201 86.3146 99.4705 82.5597 99.5701 78.7806L99.5301 25.0006Z" />
      <path d="M159.49 24.9993C159.418 21.2604 160.151 17.5499 161.64 14.1193C162.964 11.1181 164.914 8.43475 167.36 6.24931C169.65 4.24755 172.296 2.69392 175.16 1.66931C180.843 -0.423625 187.086 -0.423625 192.77 1.66931C195.666 2.71506 198.354 4.26569 200.71 6.24931C203.085 8.46662 204.983 11.1444 206.29 14.1193C207.778 17.5499 208.512 21.2604 208.44 24.9993V30.1493H193.84V24.9993C193.84 21.4726 192.863 18.8726 190.91 17.1993C188.959 15.5767 186.502 14.6884 183.965 14.6884C181.428 14.6884 178.97 15.5767 177.02 17.1993C175.067 18.866 174.09 21.466 174.09 24.9993V78.7793C174.09 82.3126 175.067 84.9126 177.02 86.5793C178.974 88.1946 181.43 89.0783 183.965 89.0783C186.5 89.0783 188.956 88.1946 190.91 86.5793C192.863 84.906 193.84 82.306 193.84 78.7793V57.5993H182.25L187.48 46.7193H208.48V78.7793C208.576 82.5591 207.842 86.314 206.33 89.7793C205.02 92.6682 203.119 95.2505 200.75 97.3593C198.428 99.424 195.733 101.026 192.81 102.079C187.129 104.186 180.881 104.186 175.2 102.079C172.309 101.047 169.655 99.4419 167.4 97.3593C164.961 95.2823 163.008 92.6946 161.68 89.7793C160.167 86.314 159.433 82.5591 159.53 78.7793L159.49 24.9993Z" />
      <path d="M91.0501 11.4199V0.919922H79.8601H65.2701H54.0801V11.4199H65.2701V92.3199H54.0801V102.82H65.2701H79.8601H91.0501V92.3199H79.8601V11.4199H91.0501Z" />
    </svg>
  );
}
