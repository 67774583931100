import React from "react";

import Image from "next/image";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const carouselBreakpoints = {
  desktop: {
    breakpoint: { max: 6000, min: 1024 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
};

export default function ContactModal() {
  return (
    <Carousel
      arrows={true}
      responsive={carouselBreakpoints}
      ssr={true}
      infinite={true}
      autoPlay={true}
      autoPlaySpeed={3000}
      // customTransition="transform 6s linear"
      // transitionDuration={6000}
    >
      <div className="client-logo">
        <Image
          priority={true}
          layout="intrinsic"
          width={150}
          height={66}
          src="/img/partners/cleanscapes.png"
          className="img-fluid"
          alt="Clean Scapes logo"
        />
      </div>

      <div className="client-logo">
        <Image
          priority={true}
          layout="intrinsic"
          width={198}
          height={56}
          src="/img/partners/yellowstone.png"
          className="img-fluid"
          alt="Yellowstone logo"
        />
      </div>

      <div className="client-logo">
        <Image
          priority={true}
          layout="intrinsic"
          width={198}
          height={54}
          src="/img/partners/impact.png"
          className="img-fluid"
          alt="Impact Landscaping"
        />
      </div>

      <div className="client-logo">
        <Image
          priority={true}
          layout="intrinsic"
          width={170}
          height={86}
          src="/img/partners/levelgreen.png"
          className="img-fluid"
          alt="Level Green"
        />
      </div>

      <div className="client-logo">
        <Image
          priority={true}
          layout="intrinsic"
          width={150}
          height={69}
          src="/img/partners/bella-terra.png"
          className="img-fluid"
          alt="Bella Terra Landscapes"
        />
      </div>

      <div className="client-logo">
        <Image
          priority={true}
          layout="intrinsic"
          width={150}
          height={66}
          src="/img/partners/asi.png"
          className="img-fluid"
          alt="ASI Landscape Management"
        />
      </div>
      <div className="client-logo">
        <Image
          priority={true}
          layout="intrinsic"
          width={150}
          height={51}
          src="/img/partners/dixie.png"
          className="img-fluid"
          alt="Dixie Lawn and Landscaping, Inc"
        />
      </div>
      <div className="client-logo">
        <Image
          priority={true}
          layout="intrinsic"
          width={150}
          height={63}
          src="/img/partners/majestic.png"
          className="img-fluid"
          alt="Majestic Lawn Care"
        />
      </div>
      <div className="client-logo">
        <Image
          priority={true}
          layout="intrinsic"
          width={150}
          height={48}
          src="/img/partners/zeppas.png"
          className="img-fluid"
          alt="Zeppas Lawn and Landscape"
        />
      </div>

      <div className="client-logo">
        <Image
          priority={true}
          layout="intrinsic"
          width={150}
          height={46}
          src="/img/partners/taylor-anthony-365.png"
          className="img-fluid"
          alt="Taylor Anthony 365"
        />
      </div>
      <div className="client-logo">
        <Image
          priority={true}
          layout="intrinsic"
          width={150}
          height={48}
          src="/img/partners/buds-and-blades.png"
          className="img-fluid"
          alt="Buds and Blades"
        />
      </div>
      <div className="client-logo">
        <Image
          priority={true}
          layout="intrinsic"
          width={150}
          height={76}
          src="/img/partners/bumgardner-landscape.png"
          className="img-fluid"
          alt="Bumgardner Landscape"
        />
      </div>
      <div className="client-logo">
        <Image
          priority={true}
          layout="intrinsic"
          width={150}
          height={32}
          src="/img/partners/serviscape.png"
          className="img-fluid"
          alt="ServiScape"
        />
      </div>
      <div className="client-logo">
        <Image
          priority={true}
          layout="intrinsic"
          width={150}
          height={44}
          src="/img/partners/yardmaster.png"
          className="img-fluid"
          alt="Yardmasters"
        />
      </div>
      <div className="client-logo">
        <Image
          priority={true}
          layout="intrinsic"
          width={150}
          height={90}
          src="/img/partners/wards-lawn.png"
          className="img-fluid"
          alt="Ward"
        />
      </div>
      <div className="client-logo">
        <Image
          priority={true}
          layout="intrinsic"
          width={150}
          height={52}
          src="/img/partners/jba-land-management.png"
          className="img-fluid"
          alt="JBA Land Management"
        />
      </div>
      <div className="client-logo">
        <Image
          priority={true}
          layout="intrinsic"
          width={150}
          height={57}
          src="/img/partners/classic-landscaping.png"
          className="img-fluid"
          alt="Classic Landscaping"
        />
      </div>
    </Carousel>
  );
}
